<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
  >
    <!--begin::Content-->
    <div class="container" style="margin-top: 5vh;">
      <div
        style="display: flex;justify-content: space-between;
                 align-items: center;"
      >
        <div
          style="display: flex;flex-direction: row;
                  flex-wrap: wrap;
                  align-items: center;"
        >
          <i
            @click="backPage()"
            class="fa fa-chevron-left fa-2x"
            style="color: #000000;"
          ></i>
          <h2
            class="mx-3"
            style="font-weight: 400; font-size: 5vh; color: #000000;"
          >
            ลงทะเบียน
          </h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row my-3">
        <!-- <div
          style="display: flex;flex-direction: row;
                  flex-wrap: wrap;
                  align-items: center;"
        >
          <b class="mx-2">เลือกหลักสูตรที่ต้องการสมัคร</b>
          <div class="form-check form-check-custom form-check-solid me-10">
            <input
              class="form-check-input"
              type="radio"
              v-model="typeRegister"
              value="1"
              id="typeRegister1"
            />
            <label class="form-check-label" for="typeRegister1">
              หลักสูตรภาคสมัครใจ
            </label>
          </div>
          <div class="form-check form-check-custom form-check-solid me-10">
            <input
              class="form-check-input"
              type="radio"
              v-model="typeRegister"
              value="2"
              id="typeRegister2"
            />
            <label class="form-check-label" for="typeRegister2">
              หลักสูตรภาคบังคับ
            </label>
          </div>
        </div> -->
      </div>
      <div class="row mb-5">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              ข้อมูลเข้าสู่ระบบ
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required">อีเมล</label>
                  <input
                    type="email"
                    v-model="modelRegister.email"
                    class="form-control form-control-solid"
                    autocomplete="one-time-code"
                    placeholder="อีเมล"
                  />
                  <div v-if="!modelRegister.email" class="text-danger">
                    กรุณาระบุ
                  </div>
                  <div
                    v-if="modelRegister.email && !isValidEmail"
                    class="text-danger"
                  >
                    อีเมลไม่ถูกต้อง
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10 fv-row" data-kt-password-meter="true">
                  <!--begin::Wrapper-->
                  <div class="mb-1">
                    <!--begin::Label-->
                    <label class="form-label fw-bold text-dark fs-6 required">
                      รหัสผ่าน
                    </label>
                    <div style="position: relative;">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        :type="passwordFieldType"
                        placeholder=""
                        v-model="modelRegister.password"
                        name="password"
                        @input="validatePassword"
                        autocomplete="one-time-code"
                      />
                      <button
                        style="position: absolute;top: 0;right: 5px;"
                        type="button"
                        class="btn"
                        @click="switchVisibility"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                    </div>

                    <div
                      class="d-flex align-items-center mb-3"
                      data-kt-password-meter-control="highlight"
                      :key="validate"
                    >
                      <!-- <div  v-for="(item, index) in listValidate"  :key="index"
                        :class="'flex-grow-1 bg-secondary  rounded h-5px me-2' +item.class + ' ' + (validate<=item.id? 'active':'') "
                      >{{item.id}}</div> -->
                      <div
                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 "
                        :class="[validate >= 1 && 'active']"
                      ></div>
                      <div
                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 "
                        :class="[validate >= 2 && 'active']"
                      ></div>
                      <div
                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 "
                        :class="[validate >= 3 && 'active']"
                      ></div>
                      <div
                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px "
                        :class="[validate >= 4 && 'active']"
                      ></div>
                    </div>
                  </div>
                  <div class="text-danger required">
                    ใช้อักขระตั้งแต่ 8 ตัวขึ้นไป โดยมีทั้งตัวอักษร ตัวเลข และ
                    สัญลักษณ์
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label class="form-label required">ยืนยันรหัสผ่าน</label>
                  <div style="position: relative;">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      :type="passwordFieldType2"
                      placeholder=""
                      v-model="modelRegister.cpassword"
                      name="cpassword"
                      @input="validateConfirmpassword"
                      autocomplete="one-time-code"
                    />
                    <button
                      style="position: absolute;top: 0;right: 5px;"
                      type="button"
                      class="btn"
                      @click="switchVisibility2"
                    >
                      <i class="fa fa-eye"></i>
                    </button>
                  </div>

                  <div v-if="!modelRegister.cpassword" class="text-danger">
                    กรุณาระบุ
                  </div>
                  <p v-if="!validateCpassword" class="text-danger">
                    รหัสผ่านและการยืนยันไม่เหมือนกัน
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              ข้อมูลสมาชิก
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- <div class="col-12 ">
                <div class="mb-10">
                  <div
                    class="image-input image-input-empty image-input-outline"
                    id="kt_user_edit_avatar"
                  >
                    <div class="image-input-wrapper mx-auto">
                      <span @click="fileClick" class="icon-edit"
                        ><i class="fa fa-pencil-alt"></i
                      ></span>
                      <img
                        :src="
                          modelRegister.imageProfile
                            ? modelRegister.imageProfile
                            : 'media/avatars/blank.png'
                        "
                        alt=""
                        style="
                            vertical-align: middle;
                            width: 100%;
                            height: inherit;
                            object-fit: cover;
                          "
                        @error="setAltImg"
                      />
                      <p>
                        ขนาดไฟล์: สูงสุด 1 MB ไฟล์ที่รองรับ: .JPEG, .PNG
                      </p>
                    </div>
                    <input
                      type="file"
                      ref="file"
                      class="form-control form-control-lg form-control-solid mt-5"
                      name="profile_avatar"
                      v-on:change="onFileChange($event)"
                      hidden
                      accept=".png, .jpg, .jpeg"
                    />
                  </div>
                </div>
              </div> -->
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required">คำนำหน้า </label>
                  <el-select
                    class="w-100"
                    v-model="modelRegister.prefix"
                    filterable
                    placeholder="กรุณาเลือกคำนำหน้า"
                  >
                    <el-option value="นาย" label="นาย" />
                    <el-option value="นาง" label="นาง" />
                    <el-option value="นางสาว" label="นางสาว" />
                  </el-select>
                  <!-- <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="คำนำหน้า "
                    v-model="modelRegister.prefix"
                  /> -->
                  <div v-if="!modelRegister.prefix" class="text-danger">
                    กรุณาระบุ
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required"
                    >ชื่อ (กรุณาระบุเป็นภาษาไทย)</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="ชื่อ"
                    v-model="modelRegister.firstName"
                    @input="validateThai('firstName', modelRegister.firstName)"
                  />
                  <div v-if="!modelRegister.firstName" class="text-danger">
                    กรุณาระบุ
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required">นามสกุล</label>
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="นามสกุล"
                    v-model="modelRegister.lastName"
                    @input="validateThai('lastName', modelRegister.lastName)"
                  />
                  <div v-if="!modelRegister.lastName" class="text-danger">
                    กรุณาระบุ
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required"
                    >เลขประจำตัวบัตรประชาชน</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="เลขประจำตัวบัตรประชาชน"
                    :maxlength="'13'"
                    v-model="modelRegister.idCard"
                    @keypress="isNumber($event)"
                    @change="assertThaiId(modelRegister.idCard)"
                  />
                  <!-- <div v-if="!modelRegister.idCard" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                  <div
                    v-if="
                      modelRegister.idCard && modelRegister.idCard.length < 13
                    "
                    class="text-danger"
                  >
                    กรุณาระบุให้ครบ 13 ตัว
                  </div>
                  <div
                    v-if="
                      modelRegister.idCard &&
                        modelRegister.idCard.length == 13 &&
                        checkIdcard == false
                    "
                    class="text-danger"
                  >
                    กรุณาระบุให้ถูกต้อง
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label">วันเกิด</label>
                  <!-- <input
                        class="form-control form-control-lg form-control-solid"
                        type="date"
                        autocomplete="off"
                      /> -->
                  <el-config-provider :locale="locale">
                    <el-date-picker
                      v-model="modelRegister.birthDate"
                      type="date"
                      placeholder="วันเกิด"
                      format="DD/MM/YYYY"
                      value-format="DD/MM/YYYY"
                      class="w-100"
                      size="large"
                    />
                  </el-config-provider>
                  <!-- <div v-if="!modelRegister.birthDate" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required"
                    >เบอร์โทรศัพท์</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="เบอร์โทรศัพท์"
                    :maxlength="'10'"
                    v-model="modelRegister.phoneNumber"
                    @keypress="isNumber($event)"
                  />
                  <div v-if="!modelRegister.phoneNumber" class="text-danger">
                    กรุณาระบุ
                  </div>
                  <div
                    v-if="
                      modelRegister.phoneNumber &&
                        modelRegister.phoneNumber.length < 10
                    "
                    class="text-danger"
                  >
                    กรุณาระบุให้ครบ 10 ตัว
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              ที่อยู่
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label ">เลขที่</label>
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="เลขที่"
                    v-model="modelRegister.addressNo"
                  />
                  <!-- <div v-if="!modelRegister.addressNo" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                </div>
              </div>
              <!-- <div class="col-12 col-md-4">
                    <div class="mb-10">
                      <label for="" class="form-label">หมู่</label>
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder="หมู่"
                        v-model="modelRegister.village"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-10">
                      <label for="" class="form-label">ตรอก</label>
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder="ตรอก"
                        v-model="modelRegister.alley"
                      />
                      <span class="text-danger"></span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-10">
                      <label for="" class="form-label">ถนน</label>
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder="ถนน"
                        v-model="modelRegister.street"
                      />
                      <span class="text-danger"></span>
                    </div>
                  </div> -->
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label ">จังหวัด</label>
                  <el-select
                    class="w-100"
                    v-model="modelRegister.provinceId"
                    filterable
                    @change="callDistrict($event)"
                    placeholder="กรุณาเลือกจังหวัด"
                  >
                    <el-option
                      v-for="p in provinceList"
                      :key="p.provinceId"
                      :value="p.provinceCode"
                      :label="p.provinceTh"
                    />
                  </el-select>
                  <!-- <div v-if="!modelRegister.provinceId" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label ">อำเภอ</label>
                  <el-select
                    class="w-100"
                    @change="callSubDistrict($event)"
                    v-model="modelRegister.districtId"
                    filterable
                    placeholder="กรุณาเลือกอำเภอ"
                  >
                    <el-option
                      v-for="p in districtList"
                      :key="p.districtCode"
                      :value="p.districtCode"
                      :label="p.districtTh"
                    />
                  </el-select>
                  <!-- <div v-if="!modelRegister.districtId" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label ">ตำบล</label>
                  <el-select
                    class="w-100"
                    filterable
                    @change="callZipcode($event)"
                    v-model="modelRegister.subDistrictId"
                    placeholder="กรุณาเลือกตำบล"
                  >
                    <el-option
                      v-for="sd in subDistrictList"
                      :key="sd.subDistrictCode"
                      :value="sd.id"
                      :label="sd.subDistrictTh"
                    />
                  </el-select>
                  <!-- <div v-if="!modelRegister.subDistrictId" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label ">รหัสไปรษณีย์</label>
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="รหัสไปรษณีย์"
                    v-model="modelRegister.zipcode"
                  />
                  <!-- <div v-if="!modelRegister.zipcode" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              คุณวุฒิ
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required">เลือกคุณวุฒิ</label>
                  <el-select
                    class="w-100"
                    filterable
                    v-model="modelRegister.qualificationId"
                    placeholder="กรุณาเลือกคุณวุฒิ"
                  >
                    <el-option
                      v-for="q in qualificationList"
                      :key="q.code"
                      :value="q.code"
                      :label="q.name"
                    />
                  </el-select>
                  <div
                    v-if="!modelRegister.qualificationId"
                    class="text-danger"
                  >
                    กรุณาระบุ
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required"
                    >คุณวุฒิ อื่น ๆ โปรดระบุ</label
                  >
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    autocomplete="off"
                    v-model="modelRegister.qualificationOther"
                  />
                  <span class="text-danger"></span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label required">สาขาวิชา</label>
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    v-model="modelRegister.qualificationMajor"
                    name="qualificationMajor"
                    autocomplete="off"
                  />
                  <span class="text-danger"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              สถานที่ทำงาน
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label">ประเภทของหน่วยงาน</label>
                  <el-select
                    class="w-100"
                    filterable
                    placeholder="กรุณาเลือกประเภทของหน่วยงาน"
                    v-model="modelRegister.officeTypeId"
                  >
                    <el-option
                      v-for="o in officeTypeList"
                      :key="o.code"
                      :value="o.code"
                      :label="o.name"
                    />
                  </el-select>
                  <span class="text-danger"></span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label">ประสบการณ์ทำงาน (ปี)</label>

                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="modelRegister.officeExperience"
                    placeholder=""
                    name="officeExp"
                    autocomplete="off"
                  />
                  <span class="text-danger"></span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label ">หน้าที่ปัจจุบัน</label>
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    v-model="modelRegister.officePosition"
                    name="officePosition"
                    autocomplete="off"
                  />
                  <!-- <div v-if="!modelRegister.officePosition" class="text-danger">
                    กรุณาระบุ
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="mb-10">
                  <label for="" class="form-label">ลักษณะงานที่ทำ</label>
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder=""
                    v-model="modelRegister.officeJobDesc"
                    name="officeJobDesc"
                    autocomplete="off"
                  />
                  <span class="text-danger"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fv-row mb-10">
        <label class="form-check form-check-custom form-check-solid">
          <input
            class="form-check-input"
            type="checkbox"
            name="toc"
            v-model="modelRegister.toc"
            value="true"
          />
          <span class="form-check-label fw-bold text-gray-700 fs-6 required">
            ฉันยอมรับ
            <a
              href="#"
              class="btn btn-link"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_policy"
            >
              เงื่อนไขนโยบายและความเป็นส่วนตัว
            </a>
          </span>
        </label>
        <div v-if="!modelRegister.toc" class="text-danger">
          กรุณาระบุ
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end;" class="mb-5">
        <button
          @click="backPage()"
          type="button"
          class="btn mx-2"
          style="background-color:#FF7786;color:#ffffff"
        >
          ยกเลิก
        </button>
        <button
          type="button"
          :disabled="validate != 4 || !validateCpassword || !checkIdcard"
          @click="onSubmitRegister"
          class="btn   mx-2"
          style="background-color:#323563;color:#ffffff"
        >
          ลงทะเบียน
        </button>
      </div>
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <!-- <div class="d-flex flex-center flex-column-auto p-10">
      <div class="d-flex align-items-center fw-bold fs-6">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div>
    </div> -->
    <!--end::Footer-->
  </div>
  <div class="modal fade" ref="newTargetModalRef" id="kt_modal_policy">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="container mt-5">
            <div>
              <h1>
                นโยบายเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน Website
                Policy of Department of Alternative Energy Development and
                Efficiency จัดทำเมื่อวันที่ 15 กันยายน 2557
              </h1>
              <p>
                <b>1. วัตถุประสงค์
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานจัดทำเว็บไซต์ขึ้นเพื่อให้บริการ
                ข้อมูลข่าวสารแก่ประชาชนทั่วไป
                และบุคลากรในสังกัดกรมพัฒนาพลังงานทดแทนและอนุรักษ์ พลังงาน
                ในการใช้บริการเว็บไซต์ของผู้ใช้บริการจะอยู่ภายใต้เงื่อนไขและข้อกำหนดดังต่อไปนี้
                ผู้ใช้บริการจึงควรศึกษาเงื่อนไข
                และข้อกำหนดการใช้งานเว็บไซต์และ/หรือเงื่อนไขและข้อตกลง
                อื่นใดที่กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานได้แจ้งให้ทราบบนเว็บไซต์โดยละเอียด
                ก่อนการเข้าใช้บริการ
                ทั้งนี้ในการใช้บริการให้ถือว่าผู้ใช้บริการได้ตกลงที่จะปฏิบัติตามเงื่อนไข
                และข้อกำหนดการให้บริการที่กำหนดไว้นี้หากผู้ใช้บริการไม่ประสงค์ที่จะผูกพันตามข้อกำหนด
                และเงื่อนไขการให้บริการ
                ขอความกรุณาท่านยุติการเข้าชมและใช้งานเว็บไซต์นี้ในทันที</b>
              </p>
              <p>
                <b>2. เงื่อนไขและข้อกำหนดการใช้งานเว็บไซต์</b>
                <br />2.1 ผู้ใช้บริการอาจได้รับ เข้าถึง สร้าง ส่งหรือแสดงข้อมูล
                เช่น ไฟล์ข้อมูล ข้อความ ลายลักษณ์อักษร
                ซอฟต์แวร์คอมพิวเตอร์ดนตรีไฟล์เสียง หรือเสียงอื่นๆ ภาพถ่าย วิดีโอ
                หรือรูปภาพอื่นๆ โดยเป็นส่วนหนึ่งของบริการหรือโดยผ่านการใช้บริการ
                ซึ่งต่อไปนี้จะ เรียกว่า “เนื้อหา” <br />
                2.2 เนื้อหาที่นำเสนอต่อผู้ใช้บริการ
                อาจได้รับการคุ้มครองโดยสิทธิในทรัพย์สิน
                ทางปัญญาของเจ้าของเนื้อหานั้น
                ผู้ใช้บริการไม่มีสิทธิเปลี่ยนแปลงแก้ไข จำหน่ายจ่ายโอนหรือ
                สร้างผลงานต่อเนื่อง
                โดยอาศัยเนื้อหาดังกล่าวไม่ว่าจะทั้งหมดหรือบางส่วน
                เว้นแต่ผู้ใช้บริการจะ ได้รับอนุญาตโดยชัดแจ้ง
                จากเจ้าของเนื้อหานั้น <br />
                2.3 ผู้ใช้บริการอาจพบเนื้อหาที่ไม่เหมาะสม หรือหยาบคาย
                อันก่อให้เกิดความ ไม่พอใจ ภายใต้ความเสี่ยงของตนเอง
                <br />
                2.4 กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานทรงไว้ซึ่งสิทธิในการ
                คัดกรอง ตรวจทาน ทำเครื่องหมาย เปลี่ยนแปลง แก้ไข ปฏิเสธ
                หรือลบเนื้อหาใดๆ ที่ไม่ เหมาะสมออกจากบริการ
                ซึ่งกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานอาจจัดเตรียม 2
                เครื่องมือในการคัดกรองเนื้อหาอย่างชัดเจน โดยไม่ขัดต่อกฎหมาย
                กฎระเบียบของทางราชการที่ เกี่ยวข้อง <br />
                2.5 เว็บไซต์นี้อาจหยุดให้บริการเป็นการชั่วคราวหรือถาวร
                หรือยกเลิกการ ให้บริการ แก่ผู้ใช้บริการรายใดเป็นการเฉพาะ
                หากการให้บริการดังกล่าวส่งผลกระทบต่อ ผู้ใช้บริการอื่นๆ
                หรือขัดแย้งต่อกฎหมาย โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                <br />
                2.6 การหยุดหรือการยกเลิกบริการตามข้อ <br>2.5
                ผู้ใช้บริการจะไม่สามารถเข้าใช้ บริการ
                และเข้าถึงรายละเอียดบัญชีของผู้ใช้บริการ ไฟล์เอกสารใดๆ
                หรือเนื้อหาอื่นๆ ที่อยู่ใน บัญชีของผู้ใช้บริการได้<br />
                2.7 ในกรณีที่เว็บไซต์นี้หยุดให้บริการเป็นการถาวร
                หรือยกเลิกบริการแก่ ผู้ใช้บริการ
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานมีสิทธิในการลบข้อมูลต่างๆ
                ที่อยู่ใน
                บัญชีของผู้ใช้บริการได้โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
              </p>
              <p>
                <b>3. สิทธิหน้าที่และความรับผิดชอบของผู้ใช้บริการ</b> <br />3.1 ผู้ใช้บ
                ริการจะให้ข้อมูลเกี่ยวกับตนเอง เช่น ข้อมูลระบุตัวตนหรือ
                รายละเอียดการติดต่อ ที่ถูกต้อง เป็นจริง และเป็นปัจจุบันเสมอ
                แก่เว็บไซต์อันเป็นส่วนหนึ่งของ กระบวนการลงทะเบียนใช้บริการ
                หรือการใช้บริการที่ต่อเนื่อง <br />3.2
                ผู้ใช้บริการจะใช้บริการเว็บไซต์นี้เพื่อวัตถุประสงค์ที่ได้รับอนุญาตตาม
                ข้อกำหนดของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                และไม่ขัดต่อกฎหมาย กฎ ระเบียบ ข้อบังคับ
                หลักปฏิบัติที่เป็นที่ยอมรับโดยทั่วไป <br />3.3
                ผู้ใช้บริการจะไม่เข้าใช้หรือพยายามเข้าใช้บริการหนึ่งบริการใดโดยวิธีอื่น
                รวมถึง การใช้วิธีการอัตโนมัติ(การใช้สคริปต์)
                นอกจากช่องทางที่กรมพัฒนาพลังงานทดแทน
                และอนุรักษ์พลังงานจัดเตรียมไว้ให้เว้นแต่ผู้ใช้บริการจะได้รับอนุญาตจากกรมพัฒนาพลังงาน
                ทดแทนและอนุรักษ์พลังงาน โดยชัดแจ้งให้ทำเช่นนั้นได้ <br />3.4
                ผู้ใช้บริการจะไม่ทำหรือมีส่วนร่วมในการขัดขวางหรือรบกวนบริการของ
                เว็บไซต์รวมทั้งเครื่องแม่ข่ายและเครือข่ายที่เชื่อมต่อกับบริการ
                <br />3.5 ผู้ใช้บริการจะไม่ทำสำเนา คัดลอก ทำซ้ำ ขาย แลกเปลี่ยน
                หรือขายต่อ บริการ เพื่อวัตถุประสงค์ใดๆ
                เว้นแต่ผู้ใช้บริการจะได้รับอนุญาตจากกรมพัฒนาพลังงานทดแทน
                และอนุรักษ์พลังงาน โดยชัดแจ้งให้ทำเช่นนั้นได้ <br />3 3.6
                ผู้ใช้บริการมีหน้าที่ในการรักษาความลับของรหัสผ่านที่เชื่อมโยงกับบัญชีใดๆ
                ที่ใช้ในการเข้าถึงบริการ <br />3.7
                ผู้ใช้บริการจะเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อบุคคลใดๆ รวมถึง
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                ในความเสียหายอันเกิดจากการละเมิด ข้อกำหนด
              </p>
              <p>
               <b>4. การเชื่อมโยงกับเว็บไซต์อื่นๆ</b>  <br />4.1
                การเชื่อมโยงไปยังเว็บไซต์อื่นเป็นเพียงการให้บริการเพื่ออำนวยความ
                สะดวก แก่ผู้ใช้บริการเท่านั้น
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานมิได้มีส่วน เกี่ยวข้อง
                หรือมีอำนาจควบคุม รับรอง ความถูกต้อง ความน่าเชื่อถือ
                ตลอดจนความรับผิดชอบ ในเนื้อหาข้อมูลของเว็บไซต์นั้นๆ
                และกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานไม่
                รับผิดชอบต่อเนื้อหาใด ๆ
                ที่แสดงบนเว็บไซต์อื่นที่เชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงาน
                ทดแทนและอนุรักษ์พลังงาน หรือต่อความเสียหายใดๆ
                ที่เกิดขึ้นจากการเข้าเยี่ยมชมเว็บไซต์
                ดังกล่าวและการเชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                <br />4.2
                กรณีต้องการเชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและ
                อนุรักษ์พลังงาน ผู้ใช้บริการสามารถเชื่อมโยง
                มายังหน้าแรกของเว็บไซต์ได้โดยแจ้งความ ประสงค์เป็นหนังสือ
                แต่หากต้องการเชื่อมโยงมายังหน้าภายในของเว็บไซต์นี้จะต้องได้รับความ
                ยินยอมเป็นหนังสือจากกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานแล้วเท่านั้น
                และในการ ให้ความยินยอมดังกล่าว
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานขอสงวนสิทธิ์ที่จะ
                กำหนดเงื่อนไขใดๆ
                ไว้ด้วยก็ได้ในการที่เว็บไซต์อื่นที่เชื่อมโยงมายังเว็บไซต์ของกรมพัฒนา
                พลังงานทดแทนและอนุรักษ์พลังงานจะไม่รับผิดชอบต่อเนื้อหาใดๆ
                ที่แสดงบนเว็บไซต์ที่
                เชื่อมโยงมายังเว็บไซต์ของกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                หรือต่อความเสียหาย ใดๆ ที่เกิดขึ้นจากการใช้เว็บไซต์เหล่านั้น
              </p>
              <p>
                <b>5. การปฏิเสธความรับผิด</b> <br />5.1
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานจะไม่รับผิดต่อความ
                เสียหายใดๆ รวมถึง ความเสียหาย สูญเสียและ
                ค่าใช้จ่ายที่เกิดขึ้นไม่ว่าโดยตรงหรือโดยอ้อม ที่
                เป็นผลหรือสืบเนื่องจากการที่ผู้ใช้เข้าใช้เว็บไซต์นี้หรือเว็บไซต์ที่เชื่อมโยงกับเว็บไซต์นี้หรือต่อ
                4 ความเสียหาย สูญเสีย
                หรือค่าใช้จ่ายที่เกิดจากความล้มเหลวในการใช้งาน ความผิดพลาด
                การละเว้น การหยุดชะงัก ข้อบกพร่อง ความไม่สมบูรณ์คอมพิวเตอร์ไวรัส
                ถึงแม้ว่ากรมพัฒนา พลังงานทดแทนและอนุรักษ์พลังงาน
                จะได้รับแจ้งว่าอาจจะเกิดความเสียหาย สูญเสียหรือ
                ค่าใช้จ่ายดังกล่าวขึ้น
                นอกจากนี้กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                ไม่รับผิดต่อผู้ใช้ เว็บไซต์หรือบุคคลจากการเรียกร้องใดๆ
                ที่เกิดขึ้นจากบนเว็บไซต์หรือเนื้อหาใดๆ ซึ่งรวมถึงการ
                ตัดสินใจหรือการกระทำใดๆ ที่เกิดจาก
                ความเชื่อถือในเนื้อหาดังกล่าวของผู้ใช้เว็บไซต์หรือใน
                ความเสียหายใดๆ ไม่ว่าความเสียหายทางตรง หรือทางอ้อม รวมถึง
                ความเสียหายอื่นใดที่อาจ
                เกิดขึ้นได้ผู้ใช้บริการยอมรับและตระหนักดีว่ากรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                จะไม่ต้องรับผิดชอบต่อการกระทำใดของผู้ใช้บริการทั้งสิ้น
              </p>
              <p>
                <b>6. กรรมสิทธิ์และสิทธิในทรัพย์สินทางปัญญา</b> <br />6.1
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงานหรือผู้ให้อนุญาตแก่เว็บไซต์
                เป็นผู้มีสิทธิตามกฎหมายแต่เพียงผู้เดียวใน
                กรรมสิทธิ์ผลประโยชน์ทั้งหมด รวมถึงสิทธิใน ทรัพย์สินทางปัญญาใดๆ
                ที่มีอยู่ในบริการ ซึ่งกรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
                หรือผู้ให้อนุญาตแก่เว็บไซต์เป็นผู้จัดทำขึ้น
                ไม่ว่าสิทธิเหล่านั้นจะได้รับการจดทะเบียนไว้หรือไม่ก็ ตาม
                <br />6.2
                ผู้ใช้บริการจะต้องไม่เปิดเผยข้อมูลที่กรมพัฒนาพลังงานทดแทนและอนุรักษ์
                พลังงาน กำหนดให้เป็นความลับ
                โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน <br />6.3
                ผู้ใช้บริการจะต้องไม่ใช้ชื่อทางการค้า เครื่องหมายการค้า
                เครื่องหมายการ บริการ
                ตราสัญลักษณ์ชื่อโดเมนของเว็บไซต์โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจาก
                กรมพัฒนาพลังงานทดแทนและอนุรักษ์พลังงาน
              </p>
              <p>
                <b>7. กฎหมายที่ใช้บังคับ</b> <br />7.1 การตีความ
                และการบังคับตามเงื่อนไขการให้บริการฉบับนี้ให้เป็นไปตาม กฎหมายไทย
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            style="border-radius: 21px"
          >
            ปิด
          </button>
          <button
            type="button"
            class="btn "
            style="border-radius: 21px;background: #31B67C;color: #FFF;"
            @click="setToc"
          >
            ยอมรับ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import th from "@element-plus/locale/lang/th.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import Password from "vue3-password-strength-meter";
import axios from "axios";
const typeRegister = ref("1");
const checkIdcard = ref(false);
const modelRegister = ref({
  email: "",
  password: "",
  cpassword: "",
  prefix: "",
  firstName: "",
  lastName: "",
  idCard: "",
  birthDate: "",
  phoneNumber: "",
  addressNo: "",
  village: "",
  alley: "",
  street: "",
  provinceId: "",
  districtId: "",
  subDistrictId: "",
  zipcode: "",
  qualificationId: "",
  qualificationOther: "",
  qualificationMajor: "",
  officeTypeId: "",
  officeExperience: "",
  officePosition: "",
  officeJobDesc: "",
  toc: false,
  imageProfile: "",
  file: null,
});
const modelMandatory = ref({
  companyElectric: "",
  companyTransformer: "",
  companyHeat: "",
  companyFuel: "",
  machines: [],
  machineOther: "",
  technologies: [],
  technologieOther: "",
  warrantorFirstname: "",
  warrantorLastname: "",
  warrantorPosition: "",
  warrantorOffice: "",
  qualificationImages: [],
  qualificationAvatar: "",
  delImages: [],
  fileAvatar: null,
  fileImages: [],
  imagesList: [],
});
const mandatory = ref([
  {
    facId: "",
    preFactoryNo: "",
    preBuildingNo: "",
    officeTypeId: "",
    factorySizeId: "",
    factoryTypes: [],
    factoryTypeOther: "",
    buildingSizeId: "",
    buildingTypes: [],
    buildingTypeOther: "",
    search: "",
  },
]);
const factory = ref([
  {
    address: "",
    subDistrictName: "",
    districtName: "",
    provinceName: "",
    zipcode: "",
  },
]);
export default defineComponent({
  name: "sign-up",
  components: {
    // Form,
    // Password,
  },
  data() {
    return {
      locale: th,
      imageUrl: process.env.VUE_APP_API_URL,
      provinceList: [],
      districtList: [],
      subDistrictList: [],
      qualificationList: [],
      officeTypeList: [],
      myCourseTypeList: [],
      myCourseSize: 0,
      factoriesList: [{}],
      buildingSizesList: [],
      buildingTypesList: [],
      technologiesList: [],
      machinesList: [],
      factorySizesList: [],
      factoryTypesList: [],
      validate: 0,
      validateCpassword: true,
      passwordFieldType: "password",
      passwordFieldType2: "password",
    };
  },

  setup() {
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const router = useRouter();
    const tab1 = ref(false);
    const tab2 = ref(false);
    const tab3 = ref(false);
    const tab4 = ref(false);
    // modelRegister: {},

    const submitButton = ref<HTMLElement | null>(null);
    const checkRequire = () => {
      if (
        modelRegister.value.email &&
        modelRegister.value.password &&
        modelRegister.value.cpassword &&
        modelRegister.value.prefix &&
        modelRegister.value.firstName &&
        modelRegister.value.lastName &&
        // modelRegister.value.idCard &&
        // modelRegister.value.birthDate &&
        modelRegister.value.phoneNumber &&
        modelRegister.value.qualificationOther &&
        modelRegister.value.qualificationMajor &&
        // modelRegister.value.addressNo &&
        // modelRegister.value.provinceId &&
        // modelRegister.value.districtId &&
        // modelRegister.value.subDistrictId &&
        modelRegister.value.qualificationId &&
        // modelRegister.value.officePosition &&
        modelRegister.value.toc && 
        checkIdcard.value == true
      ) {
        tab1.value = true;
      } else {
        tab1.value = false;
      }
      // && tab2.value && tab3.value && tab4.value
      if (
        tab1.value &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          modelRegister.value.email
        )
      ) {
        return true;
      } else {
        return false;
      }
    };
    const onSubmitRegister = async () => {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const machines: any = [];
      for (const loopMachine of modelMandatory.value.machines) {
        machines.push({
          machineId: loopMachine,
          other: loopMachine == 6 ? modelMandatory.value.machineOther : "",
        });
      }

      const technologies: any = [];
      for (const loopTech of modelMandatory.value.technologies) {
        technologies.push({
          techId: loopTech,
          other: loopTech == 6 ? modelMandatory.value.technologieOther : "",
        });
      }
      if (checkRequire()) {
        let birthDate;
        birthDate =
          modelRegister.value.birthDate.split("/")[1] +
          "/" +
          modelRegister.value.birthDate.split("/")[0] +
          "/" +
          parseInt(modelRegister.value.birthDate.split("/")[2]);
        birthDate = new Date(birthDate).getTime();
        const formData = new FormData();
        formData.append("email", modelRegister.value.email);
        formData.append("username", modelRegister.value.email);
        formData.append("password", modelRegister.value.password);
        formData.append("prefix ", modelRegister.value.prefix);
        formData.append("firstName", modelRegister.value.firstName);
        formData.append("lastName", modelRegister.value.lastName);
        formData.append("idCard", modelRegister.value.idCard);
        formData.append("birthDate", birthDate);
        formData.append("addressId", modelRegister.value.subDistrictId);
        formData.append("addressNo", modelRegister.value.addressNo);
        formData.append("phoneNumber", modelRegister.value.phoneNumber);
        formData.append("qualificationId", modelRegister.value.qualificationId);
        formData.append(
          "qualificationOther",
          modelRegister.value.qualificationOther
        );
        formData.append(
          "qualificationMajor",
          modelRegister.value.qualificationMajor
        );
        formData.append("subDistrictId", modelRegister.value.subDistrictId);
        formData.append("officeTypeId", modelRegister.value.officeTypeId);
        formData.append(
          "officeExperience",
          modelRegister.value.officeExperience
        );
        formData.append("officeJobDesc", modelRegister.value.officeJobDesc);
        formData.append("officePosition", modelRegister.value.officePosition);
        /* eslint-disable */
        if (modelRegister.value.file != null) {
          const formUploadImage = new FormData();
          formUploadImage.append("file ", modelRegister.value.file!);
          let uploadImage = await axios.post(
            process.env.VUE_APP_API_URL + "/uploadImage",
            formUploadImage,
            { headers: { token: localStorage.getItem("tokenGuest") } }
          );
          // console.log(uploadImage);
          formData.append("imageProfile", uploadImage.data.data!);
        }
        axios
          .post(process.env.VUE_APP_API_URL + "/register", formData)
          .then((res) => {
            if (res.data.code == 200) {
              Swal.fire({
                title: "ลงทะเบียนสำเร็จ",
                text: "กรุณาตรวจสอบอีเมลเพื่อยืนยันตัวตน",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-primary",
                },
              }).then(function() {
                router.go(-1);
              });
            } else {
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: res.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text:
                error.response != undefined
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      } else {
        Swal.close();
      }
    };
    const addFactory = () => {
      mandatory.value.push({
        facId: "",
        preFactoryNo: "",
        preBuildingNo: "",
        officeTypeId: "",
        factorySizeId: "",
        factoryTypes: [],
        factoryTypeOther: "",
        buildingSizeId: "",
        buildingTypes: [],
        buildingTypeOther: "",
        search: "",
      });
      factory.value.push({
        address: "",
        subDistrictName: "",
        districtName: "",
        provinceName: "",
        zipcode: "",
      });
    };
    // onMounted(() => {
    //   store.dispatch(Actions.REMOVE_BODY_ATTRIBUTE, {
    //     qulifiedName: "style",
    //   });
    // });

    // store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
    //   qulifiedName: "style",
    //   value: "background-image: url(media/patterns/header-bg.jpg)",
    // });
    const backPage = () => {
      router.go(-1);
    };
    const setToc = () => {
      modelRegister.value.toc = true;
      hideModal(newTargetModalRef.value);
    };
    const assertThaiId = (thaiId: string): boolean => {
      // console.log("assertThaiId : " + thaiId);
      const m = thaiId.match(/(\d{12})(\d)/);
      if (!m) {
        console.warn("Bad input from user, invalid thaiId=", thaiId);
        throw new Error("thai-id-must-be-13-digits");
      }
      const digits = m[1].split("");
      const sum = digits.reduce((total: number, digit: string, i: number) => {
        return total + (13 - i) * +digit;
      }, 0);
      const lastDigit = `${(11 - (sum % 11)) % 10}`;
      const inputLastDigit = m[2];
      if (lastDigit !== inputLastDigit) {
        console.warn("Bad input from user, invalid checksum thaiId=", thaiId);
        // throw new Error("thai-id-checksum-mismatched");
        checkIdcard.value = false;
        return false;
      } else {
        checkIdcard.value = true;
        return true;
      }
    };
    return {
      // registration,
      onSubmitRegister,
      submitButton,
      modelRegister,
      checkRequire,
      tab1,
      tab2,
      tab3,
      tab4,
      modelMandatory,
      typeRegister,
      backPage,
      mandatory,
      addFactory,
      factory,
      setToc,
      hideModal,
      newTargetModalRef,
      assertThaiId,
      checkIdcard,
    };
  },
  computed: {
    isValidEmail() {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        modelRegister.value.email
      );
    },
  },
  methods: {
    validateThai(input, value) {
      const thaiRegex = /^[\u0E00-\u0E7F]+$/;
      let check = thaiRegex.test(value);
      if (input == "firstName" && check != true) {
        modelRegister.value.firstName = "";
      }
      if (input == "lastName" && check != true) {
        modelRegister.value.lastName = "";
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    switchVisibility2() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
    },
    onFileChange(event) {
      modelRegister.value.file = event.target.files[0];
      modelRegister.value.imageProfile = URL.createObjectURL(
        event.target.files[0]
      );
    },
    onFileChangeMultiple(event) {
      const deleteFile: any = [];
      if (modelMandatory.value.imagesList) {
        for (const loopDel of modelMandatory.value.imagesList) {
          deleteFile.push(loopDel);
        }
      }
      modelMandatory.value.delImages = deleteFile;
      modelMandatory.value.fileImages = event.target.files;
      if (event.target.files.length > 0 && event.target.files.length == 3) {
        const image: any = [];
        for (const loopFile of event.target.files) {
          image.push(URL.createObjectURL(loopFile));
        }
        modelMandatory.value.qualificationImages = image;
      } else {
        alert("จำนวนรูไม่ถูกต้อง");
      }
    },
    onFileChangeAvatar(event) {
      modelMandatory.value.fileAvatar = event.target.files[0];
      modelMandatory.value.qualificationAvatar = URL.createObjectURL(
        event.target.files[0]
      );
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      // eslint-disable-next-line prefer-const
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async callDistrict(provinceId) {
      const districts = await axios.get(
        process.env.VUE_APP_API_URL + "/getDistrict?provinceCode=" + provinceId
      );
      this.districtList = districts.data.data;
      modelRegister.value.districtId = "";
      modelRegister.value.subDistrictId = "";
      modelRegister.value.zipcode = "";
    },
    async callSubDistrict(districtId) {
      const subDistricts = await axios.get(
        process.env.VUE_APP_API_URL +
          `/getSubDistrict?provinceCode=${modelRegister.value.provinceId}&districtCode=${districtId}`
      );
      this.subDistrictList = subDistricts.data.data;
      modelRegister.value.subDistrictId = "";
      modelRegister.value.zipcode = "";
    },
    callZipcode(subDistrictId) {
      if (this.subDistrictList.length > 0) {
        for (const loopData of this.subDistrictList) {
          if (loopData["id"] == subDistrictId) {
            modelRegister.value.zipcode = loopData["zipcode"];
          }
        }
      }
    },

    async callFactories(index) {
      const factories = await axios.get(
        process.env.VUE_APP_API_URL +
          "/api/v1/m/factories?search=" +
          mandatory.value[index].search
      );
      this.factoriesList[index] = factories.data.result;
    },
    async callFactoriesId(index) {
      const factorylist = await axios.get(
        process.env.VUE_APP_API_URL +
          "/api/v1/m/factories/" +
          mandatory.value[index].facId
      );
      factory.value[index] = factorylist.data.result;
      mandatory.value[index].search = factory.value[index]["factoryName"];
      this.callFactories(index);
    },
    // setBuildingTypes(id) {
    //   if (
    //     modelMandatory.value &&
    //     modelMandatory.value.buildingTypes.length > 0
    //   ) {
    //     for (const loopdata of modelMandatory.value.buildingTypes) {
    //       if (loopdata["buildingTypeId"] == id) {
    //         return true;
    //       }
    //     }
    //   } else {
    //     return false;
    //   }
    // },
    fileMultipleClick() {
      // console.log(this.$refs.fileMultiple);
      const input: any = this.$refs.fileMultiple;
      input.click();
    },
    fileAvatarClick() {
      // console.log(this.$refs.fileAvatar);
      const input: any = this.$refs.fileAvatar;
      input.click();
    },
    fileClick() {
      // console.log(this.$refs.file);
      const input: any = this.$refs.file;
      input.click();
    },
    validatePassword() {
      this.validate = 0;
      const letterRegex = /[a-zA-Z]/;
      const numberRegex = /[0-9]/;
      const symbolRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

      const isValidLength = modelRegister.value.password.length >= 8;
      const hasLetter = letterRegex.test(modelRegister.value.password);
      const hasNumber = numberRegex.test(modelRegister.value.password);
      const hasSymbol = symbolRegex.test(modelRegister.value.password);

      if (isValidLength) {
        this.validate = this.validate + 1;
      }
      if (hasLetter) {
        this.validate = this.validate + 1;
      }
      if (hasNumber) {
        this.validate = this.validate + 1;
      }
      if (hasSymbol) {
        this.validate = this.validate + 1;
      }
      // console.log("validatePassword");
      // console.log(this.validate);
      // return isValidLength && hasLetter && hasNumber && hasSymbol;
    },
    validateConfirmpassword() {
      if (modelRegister.value.password == modelRegister.value.cpassword) {
        this.validateCpassword = true;
      } else {
        this.validateCpassword = false;
      }
    },
  },
  async mounted() {
    if (localStorage.getItem("reloaded")) {
      location.reload();
      localStorage.removeItem("reloaded");
    }
    const provinces = await axios.get(
      process.env.VUE_APP_API_URL + "/getProvince"
    );
    this.provinceList = provinces.data.data;
    // console.log(provinces.data);

    const qualifications = await axios.get(
      process.env.VUE_APP_API_URL + "/getQualification"
    );
    this.qualificationList = qualifications.data.data;

    const officeTypes = await axios.get(
      process.env.VUE_APP_API_URL + "/getOfficeType"
    );
    this.officeTypeList = officeTypes.data.data;
    //  const factories = await axios.get(
    //   process.env.VUE_APP_API_URL + "/api/v1/m/user/factories"
    // );
    // this.factoriesList = factories.data.result;
    // const buildingSizes = await axios.get(
    //   process.env.VUE_APP_API_URL + "/api/v1/m/user/buildingSizes"
    // );
    // this.buildingSizesList = buildingSizes.data.result;

    // const buildingTypes = await axios.get(
    //   process.env.VUE_APP_API_URL + "/api/v1/m/user/buildingTypes"
    // );
    // this.buildingTypesList = buildingTypes.data.result;

    // const factorySizes = await axios.get(
    //   process.env.VUE_APP_API_URL + "/api/v1/m/user/factorySizes"
    // );
    // this.factorySizesList = factorySizes.data.result;

    // const factoryTypes = await axios.get(
    //   process.env.VUE_APP_API_URL + "/api/v1/m/user/factoryTypes"
    // );
    // this.factoryTypesList = factoryTypes.data.result;

    // const technologies = await axios.get(
    //   process.env.VUE_APP_API_URL + "/api/v1/m/user/technologies"
    // );
    // this.technologiesList = technologies.data.result;

    // const machines = await axios.get(
    //   process.env.VUE_APP_API_URL + "/api/v1/m/user/machines"
    // );
    // this.machinesList = machines.data.result;
  },
});
</script>
<style>
.image-input .image-input-wrapper {
  width: 150px;
  height: 150px;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-edit {
  position: absolute;
  right: -5px;
  top: -5px;
  background: #ffffff;
  height: 23px;
  width: 23px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #dadada;
}
</style>
